import React, {useState} from 'react';
import {redirect, useNavigate} from 'react-router-dom';
import {auth} from '../../api/api';
import Button from '@mui/material/Button';
import {TextField} from '@mui/material';
import styles from './home.module.scss';
import WordGame from '../WordGame/WordGame';

export default function HomePage(props: unknown) {
    const [login, setLogin] = useState('');
    const [pass, setPass] = useState('');
    const navigate = useNavigate();

    const onClick = async () => {
      try {
        const jwt = await auth(login, pass);
        localStorage.setItem('jwt', jwt);
        navigate('/test')
      } catch (e: any) { alert(e.message); }
    };

    return (
        <div className={styles.formContainer}>
          <TextField className={styles.input} value={login} onChange={(e) => setLogin(e.target.value)} />
          <TextField className={styles.input}  value={pass} onChange={(e) => setPass(e.target.value)} />

          <Button onClick={onClick} variant="contained" className={styles.button} >
            Login
          </Button>
        </div>
    )
}
