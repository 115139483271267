import {libraryDownload, LibrarySearchResponse} from '../../api/api';
import styles from './library.module.scss';

export const SearchResultsList = ( { searchResults }: { searchResults: LibrarySearchResponse }) => {

  const onBookClick = async (bookId: number) => {
    const result = await libraryDownload(bookId);
    if (result.pending) {
      alert('Not yet, try again in 5 minutes');
      return;
    }
    const blob = await result.promiseBlob!;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.setAttribute("download", result.fileName!);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <div>
      <p>Authors</p>
      <ul className={styles.linkUl}>
        {searchResults.authors.map(author => <li key={author.id}>{author.name}</li>)}
      </ul>

      <p>Books</p>
      <ul className={styles.linkUl}>
        {searchResults.books.map(book => (
          <li key={book.id} onClick={() => onBookClick(book.id)}>
            {book.title}
          </li>)
        )}
      </ul>
    </div>
  );
}
