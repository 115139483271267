import React from 'react';
import { Outlet, useNavigate} from 'react-router-dom';

export default function AuthLayout(props: React.PropsWithChildren<any>) {
    const jwt = localStorage.getItem('jwt');
    const navigate = useNavigate();
    console.log(props);
    if (!jwt) {
        navigate('/');
        return null;
    }
    return (
        <>
            <Outlet />
        </>
    )
}
